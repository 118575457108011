import SingleSelect from "../../SingleSelect";
import { useEffect } from "react";

const LocationField = ({
  occurrencePayload,
  setOccurrencePayload,
  trip,
  handleFieldChange,
  locations,
  linearAssetSelectionRequired,
  isRequired,
  manageOccurrences,
}) => {

  useEffect(() => {
    if (manageOccurrences) {
      if (!occurrencePayload?.linear_asset_id?.value?.id) {
        handleFieldChange("location", "");
      }
    }
  }, [occurrencePayload?.linear_asset_id?.value?.id]);

  return (
    <div className="form-control">
      {manageOccurrences ? (
        <div className="flex gap-4 items-center">
          <div className="w-32">
            <label className="font-bold">
              {" "}
              <span className="label-text">
                Location
                {isRequired && <span className="text-error"> *</span>}
              </span>
            </label>
          </div>
          <div className="w-60">
            <SingleSelect
              placeholder="Pick one"
              isLoading={locations?.isLoading}
              isDisabled={
                locations?.isLoading ||
                (linearAssetSelectionRequired && !occurrencePayload.linear_asset_id)
              }
              value={occurrencePayload.location}
              handleOnChange={(location) => handleFieldChange("location", location)}
              options={
                (Array.isArray(trip?.trip_locations) &&
                  trip?.trip_locations?.map((location) => {
                    return {
                      label: location.location.name,
                      value: location.location.id,
                    };
                  })) ||
                (Array.isArray(locations?.data) &&
                  locations?.data?.map((location) => {
                    return {
                      label: location.name,
                      value: location.id,
                    };
                  }))
              }
            />
          </div>
        </div>
      ) : (
        <>
          <label className="label">
            <span className="label-text">
              Location
              {isRequired && <span className="text-error"> *</span>}
            </span>
          </label>
          <SingleSelect
            placeholder="Pick one"
            isLoading={locations?.isLoading}
            isDisabled={
              locations?.isLoading ||
              (linearAssetSelectionRequired && !occurrencePayload.linear_asset_id)
            }
            value={occurrencePayload.location}
            handleOnChange={(location) => handleFieldChange("location", location)}
            options={
              (Array.isArray(trip?.trip_locations) &&
                trip?.trip_locations?.map((location) => {
                  return {
                    label: location.location.name,
                    value: location.location.id,
                  };
                })) ||
              (Array.isArray(locations?.data) &&
                locations?.data?.map((location) => {
                  return {
                    label: location.name,
                    value: location.id,
                  };
                }))
            }
          />
        </>
      )}
    </div>
  )
};

export default LocationField;
