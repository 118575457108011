import SingleSelect from "../../SingleSelect";
import { useEffect, useState } from "react";

const StationaryAssetsField = ({
  occurrencePayload,
  setOccurrencePayload,
  stationaryAssets,
  handleFieldChange,
  linearAssetSelectionRequired,
  isRequired,
  manageOccurrences,
}) => {
  const [stationaryAssetTypes, setStationaryAssetTypes] = useState([]);

  const sortedStationaryAssets = Array.isArray(stationaryAssets.data)
    ? stationaryAssets.data.sort((a, b) => {
      if (a.sequence === b.sequence) {
        return a.subsequence - b.subsequence;
      }
      return a.sequence - b.sequence;
    })
    : [];

  useEffect(() => {
    if (manageOccurrences) {
      if (!occurrencePayload?.linear_asset_id?.value?.stationary_asset_types) {
        setStationaryAssetTypes([]);
        handleFieldChange("stationary_asset_type", "");
        handleFieldChange("stationary_asset", "");
      }

      if (occurrencePayload?.linear_asset_id?.value?.stationary_asset_types) {
        setStationaryAssetTypes(
          occurrencePayload?.linear_asset_id?.value?.stationary_asset_types
        );
      }
    }
  }, [occurrencePayload?.linear_asset_id?.value?.stationary_asset_types]);

  return (
    <div className="form-control">
      {manageOccurrences ? (
        <div className="flex gap-4 items-center">
          <div className="w-32">
            <label className="font-bold">
              {" "}
              <span className="label-text">
                Stationary Asset
                {isRequired && <span className="text-error"> *</span>}
              </span>
            </label>
          </div>
          <div className="join">
            <div className="join-item w-28">
              <SingleSelect
                isDisabled={
                  stationaryAssets.isLoading ||
                  (linearAssetSelectionRequired && !occurrencePayload.linear_asset_id) || !occurrencePayload?.linear_asset_id?.value?.stationary_asset_types
                }
                isLoading={stationaryAssets.isLoading}
                placeholder="Type"
                value={occurrencePayload.stationary_asset_type}
                handleOnChange={(stationary_asset_type) =>
                  handleFieldChange("stationary_asset_type", stationary_asset_type)
                }
                options={stationaryAssetTypes.map((stationaryAssetType) => {
                  return { value: stationaryAssetType, label: stationaryAssetType };
                })}
              />
            </div>
            <div className="join-item w-32">
              <SingleSelect
                isDisabled={
                  stationaryAssets.isLoading ||
                  (linearAssetSelectionRequired && !occurrencePayload.linear_asset_id) || !occurrencePayload?.linear_asset_id?.value?.stationary_asset_types || !occurrencePayload?.stationary_asset_type
                }
                isLoading={stationaryAssets.isLoading}
                placeholder="Pick one"
                value={occurrencePayload.stationary_asset}
                handleOnChange={(stationary_asset) =>
                  handleFieldChange("stationary_asset", stationary_asset)
                }
                useFormatGroupLabel={true}
                options={sortedStationaryAssets.map((stationaryAsset) => {
                  const options = stationaryAsset?.assets?.map((asset) => {
                    return { value: asset.id, label: asset.name };
                  });
                  return {
                    label: stationaryAsset.location_name,
                    options: options,
                  };
                })}
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <label className="label">
            <span className="label-text">Stationary Asset</span>
            {isRequired && <span className="text-error">*</span>}
          </label>
          <SingleSelect
            isDisabled={
              stationaryAssets.isLoading ||
              (linearAssetSelectionRequired && !occurrencePayload.linear_asset_id)
            }
            isLoading={stationaryAssets.isLoading}
            placeholder="Pick one"
            value={occurrencePayload.stationary_asset}
            handleOnChange={(stationary_asset) =>
              handleFieldChange("stationary_asset", stationary_asset)
            }
            useFormatGroupLabel={true}
            options={sortedStationaryAssets.map((stationaryAsset) => {
              const options = stationaryAsset?.assets?.map((asset) => {
                return { value: asset.id, label: asset.name };
              });
              return {
                label: stationaryAsset.location_name,
                options: options,
              };
            })}
          />
        </>
      )}
    </div>
  );
};

export default StationaryAssetsField;
