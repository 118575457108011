import { UserContext } from "../../../utils/Contexts";
import { useContext } from "react";
import { APPROVED, DENIED, REQUESTED, NOT_REQUESTED } from "../../../constants/constants";

export default function WaiverStatusForm({
  payload,
  isEditMode,
  originalValues,
  setPayload,
  handleFieldChange,
  formType,
}) {
  const { currentUserContext } = useContext(UserContext);

  const hasBothPermissions = currentUserContext?.waiver_permissions?.can_request_waiver &&
    currentUserContext?.waiver_permissions?.can_respond_to_waiver;

  //if false, request button will be disabled
  const canRequestWaiver =
    !currentUserContext?.waiver_permissions?.can_request_waiver ||
    (isEditMode &&
      originalValues.waiver_status &&
      (
        originalValues?.waiver_status === "approved" ||
        originalValues?.waiver_status === "denied" ||
        originalValues?.waiver_status === APPROVED ||
        originalValues?.waiver_status === DENIED
      ));

  //if false, approved and denied buttons will be disabled
  const canRespondToWaiver =
    !currentUserContext?.waiver_permissions?.can_respond_to_waiver ||
    payload?.waiver_status === NOT_REQUESTED ||
    (isEditMode &&
      originalValues.waiver_status &&
      originalValues?.waiver_status === NOT_REQUESTED);

  return (
    <div className="form-control">
      <label className="label">
        <span className="label-text">
          Waiver Status
        </span>
      </label>
      <div className="join join-horizontal">
        <button
          disabled={canRequestWaiver || !hasBothPermissions}
          className={`btn btn-primary ${payload?.waiver_status === "requested" || payload?.waiver_status === REQUESTED ? "btn-fill" : "btn-outline"} join-item w-1/3`}
          onClick={(event) => {
            event.preventDefault();
            if (payload?.waiver_status === "requested" || payload?.waiver_status === REQUESTED) {
              handleFieldChange("waiver_status", NOT_REQUESTED, formType);
            } else {
              handleFieldChange("waiver_status", REQUESTED, formType);
            }
          }}
        >
          Requested
        </button>
        <button
          disabled={canRespondToWaiver || !hasBothPermissions}
          className={`btn btn-primary ${payload?.waiver_status === "approved" || payload?.waiver_status === APPROVED ? "btn-fill" : "btn-outline"} join-item w-1/3`}
          onClick={(event) => {
            event.preventDefault();
            if (payload?.waiver_status === "approved" || payload?.waiver_status === APPROVED) {
              if (hasBothPermissions) {
                handleFieldChange("waiver_status", NOT_REQUESTED, formType);
              } else {
                return;
              }
            } else {
              handleFieldChange("waiver_status", APPROVED, formType);
            }
          }}
        >
          Approved
        </button>
        <button
          disabled={canRespondToWaiver || !hasBothPermissions}
          className={`btn btn-primary ${payload?.waiver_status === "denied" || payload?.waiver_status === DENIED ? "btn-fill" : "btn-outline"} join-item w-1/3`}
          onClick={(event) => {
            event.preventDefault();
            if (payload?.waiver_status === "denied" || payload?.waiver_status === DENIED) {
              if (hasBothPermissions) {
                handleFieldChange("waiver_status", NOT_REQUESTED, formType);
              } else {
                return;
              }
            } else {
              handleFieldChange("waiver_status", DENIED, formType);
            }
          }}
        >
          Denied
        </button>
      </div>
    </div>
  );
}
