import Select, {
  type DropdownIndicatorProps,
  ClearIndicatorProps,
  MultiValueRemoveProps,
  components,
} from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faTimes } from "@fortawesome/pro-regular-svg-icons";

const DropDownIcon = () => {
  return <FontAwesomeIcon icon={faChevronDown} />;
};

const ClearIcon = () => {
  return <FontAwesomeIcon icon={faTimes} />;
};

const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropDownIcon />
    </components.DropdownIndicator>
  );
};

const ClearIndicator: React.FC<ClearIndicatorProps> = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <ClearIcon />
    </components.ClearIndicator>
  );
};

const MultiValueRemove = (props: MultiValueRemoveProps) => {
  return (
    <components.MultiValueRemove {...props}>
      <ClearIcon />
    </components.MultiValueRemove>
  );
};

const colourStyles = {
  control: (styles, { isDisabled }) => ({
    ...styles,

    backgroundColor: isDisabled ? "#f1f0ef" : "white",
    minHeight: "2.5rem",
    fontSize: "0.875rem",
    ":hover": {},
    ":focus": {
      borderColor: "var(--fallback-bc, oklch(var(--bc) / 0.2))",
      outlineStyle: "solid !important",
      outlineWidth: "2px !important",
      outlineOffset: "2px !important",
      outlineColor: "#00AAC3 !important",
      boxShadow: "none",
    },
    ":focus-within": {
      borderColor: "var(--fallback-bc, oklch(var(--bc) / 0.2))",
      outlineStyle: "solid !important",
      outlineWidth: "2px !important",
      outlineOffset: "2px !important",
      outlineColor: "#00AAC3 !important",
      boxShadow: "none",
    },
    "@media only screen and (max-width: 1023px)": {
      ...styles["@media only screen and (max-width: 1023px)"],
      minHeight: "2rem",
      maxHeight: "2rem",
      fontSize: "0.875rem",
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    "@media only screen and (max-width: 1023px)": {
      ...styles["@media only screen and (max-width: 1023px)"],
      paddingTop: "2px",
      paddingBottom: "2px",
    },
  }),
  placeholder: (styles, { isDisabled }) => ({
    ...styles,
    color: isDisabled ? "#808080" : "black",
  }),
  input: (styles) => ({
    ...styles,
    "@media only screen and (max-width: 1023px)": {
      ...styles["@media only screen and (max-width: 1023px)"],
      paddingTop: "2px",
      paddingBottom: "2px",
    },
  }),
  dropdownIndicator: (styles, { isDisabled }) => ({
    ...styles,
    color: isDisabled ? "#808080" : "black",
    "@media only screen and (max-width: 1023px)": {
      ...styles["@media only screen and (max-width: 1023px)"],
      paddingTop: "2px",
      paddingBottom: "2px",
    },
  }),
  clearIndicator: (styles) => ({
    ...styles,
    color: "black",
    "@media only screen and (max-width: 1023px)": {
      ...styles["@media only screen and (max-width: 1023px)"],
      paddingTop: "2px",
      paddingBottom: "2px",
    },
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    width: "0",
  }),
  menuList: (styles) => ({
    ...styles,
    background: "white",
    border: "1px solid #000",
    borderRadius: "4px",
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    background: isFocused ? "#E5F6F9" : isSelected ? "#E5F6F9" : undefined,
    color: isFocused ? "black" : isSelected ? "black" : undefined,
    zIndex: 1,
  }),
  menu: (base) => ({
    ...base,
    zIndex: 100,
    boxShadow: "none",
  }),
};

export default function MultiSelect({
  placeholder,
  options,
  handleOnChange,
  value,
  className,
  isDisabled,
}) {
  return (
    <Select
      isMulti
      styles={colourStyles}
      isDisabled={isDisabled}
      components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
      value={value || ""}
      isClearable={false}
      name="operaters"
      className={`my-select ${className ?? ""}`}
      classNamePrefix="my-select"
      options={options?.map((option) => {
        return { value: option.value, label: option.label };
      })}
      onChange={(event) => handleOnChange(event)}
    />
  );
}
