export default function PenaltiesAndIncentives({ trip }) {
    return (
        <div className="card card-compact bg-base-100 border border-base-300 prose">
            <div className="card-header py-2 px-4 border-b">
                <div className="flex justify-between">
                    <h3 className="m-0">Penalties/Incentives</h3>
                </div>
            </div>

            <div className="overflow-x-auto">
                <table className="table m-0">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Classification</th>
                            <th>Sub-Type</th>
                            <th>Amount</th>
                            <th className="text-center">Status</th>
                            <th className="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={2} className="italic">
                                No penalties/incentives found
                            </td>
                        </tr>
                        {/* <tr>
                        <td>Penalty</td>
                        <td>Performance</td>
                        <td>LP2</td>
                        <td>$4,500</td>
                        <td className="text-center">
                          <div className="badge badge-sm badge-success">Excused</div>
                        </td>
                        <td className="text-center">
                          <button className="btn btn-sm btn-outline btn-primary">
                            Reinstate
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>Penalty</td>
                        <td>Short Crew</td>
                        <td></td>
                        <td>$500</td>
                        <td className="text-center"></td>
                        <td className="text-center">
                          <button className="btn btn-sm btn-outline btn-primary">
                            Excuse
                          </button>
                        </td>
                      </tr> */}
                    </tbody>
                </table>
            </div>
        </div>
    )
}