import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/pro-regular-svg-icons";
import { TripContext } from "../../utils/Contexts";
import { useContext, useState } from "react";
import TripOccurrenceFlyout from "./trip-occurrences/TripOccurrenceFlyout";
import LoadingSpinner from "../../icons/LoadingSpinner";
import useFetchFunctions from "../../services/trips";
import { useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

export default function CancelTrip({ viewingTripDetail }) {
  const { currentTripContext, setCurrentTripContext } = useContext(TripContext);
  const [occurrenceDrawer, setOccurrenceDrawer] = useState(false);
  const [occurrenceVia, setOccurrenceVia] = useState("");

  const { cancelTrip } = useFetchFunctions();
  const queryClient = useQueryClient();

  //prepare expected props for TripOccurrenceFlyout and handleCancelTrip
  const operator_short_name =
    currentTripContext?.operator_service_day?.operator?.short_name;
  const service_date = currentTripContext?.operator_service_day?.service_date;
  const trip = currentTripContext;

  const handleCancelTrip = async () => {
    try {
      await cancelTrip(operator_short_name, service_date, trip.trip_name).then(
        (res) => {
          if (!res.error) {
            queryClient.setQueryData(
              ["trip", operator_short_name, service_date, trip.trip_name],
              res
            );
            toast.success(`Trip ${trip?.trip_name} saved`);
            setCurrentTripContext(res);
          }
        }
      );
    } catch (error) {
      console.error("error", error);
    }
  };

  const CancelButton = () => {
    if (currentTripContext?.isLoading) {
      return (
        <button className="btn btn-error" disabled>
          <LoadingSpinner />
        </button>
      );
    }
    return (
      <button
        className={"btn  btn-error"}
        onClick={() => {
          setOccurrenceVia({ action: "cancel", trip: currentTripContext });
          setOccurrenceDrawer(true);
        }}
      >
        Cancel <FontAwesomeIcon icon={faX} />
      </button>
    );
  };

  return (
    <div>
      {viewingTripDetail && <CancelButton />}
      <div className="drawer drawer-end">
        <input
          id="add-occurrence-drawer"
          type="checkbox"
          className="drawer-toggle"
          checked={occurrenceDrawer}
          onChange={() => {}}
        />

        <TripOccurrenceFlyout
          operator_short_name={operator_short_name}
          service_date={service_date}
          trip={trip}
          occurrenceDrawer={occurrenceDrawer}
          setOccurrenceDrawer={setOccurrenceDrawer}
          occurrenceVia={occurrenceVia}
          setOccurrenceVia={setOccurrenceVia}
          handleCancelTrip={handleCancelTrip}
        />
      </div>
    </div>
  );
}
