import LinearAssetField from "./LinearAssetField";
import LocationField from "./LocationField";
import TrainField from "./TrainField";
import StationaryAssetsField from "./StationaryAssetsField";

export default function DynamicOccurrenceCauseFormFields({
  occurrencePayload,
  setOccurrencePayload,
  vehicleSearchPayload,
  setVehicleSearchPayload,
  searchingVehicle,
  handleVehicleLookUp,
  stationaryAssets,
  linearAssets,
  trip,
  occurrenceCause,
  handleFieldChange,
  linearAssetSelectionRequired,
  locations,
  forceShowLinearAssets,
  forceShowAllFields,
  manageOccurrences,
  selectedOperator,
}) {
  const {
    location = "NOT SHOWN",
    linear_asset = "NOT SHOWN",
    stationary_asset = "NOT SHOWN",
    vehicle = "NOT SHOWN",
  } = occurrenceCause?.value || {};

  const isFieldVisible = (fieldValue) =>
    fieldValue === "REQUIRED" || fieldValue === "OPTIONAL";

  return (
    <>
      {(occurrenceCause || forceShowAllFields) && (
        <div>
          <hr className="mt-4 mb-3" />
          <div
            className={
              manageOccurrences ? "grid grid-cols-1 md:grid-cols-2 gap-4" : ""
            }
          >
            {(isFieldVisible(linear_asset) ||
              forceShowLinearAssets ||
              forceShowAllFields) && (
                <LinearAssetField
                  handleFieldChange={handleFieldChange}
                  isRequired={linear_asset === "REQUIRED" && !forceShowAllFields}
                  occurrencePayload={occurrencePayload}
                  setOccurrencePayload={setOccurrencePayload}
                  linearAssets={linearAssets}
                  showStartAndEnd={isFieldVisible(linear_asset)}
                  selectedOperator={selectedOperator}
                  manageOccurrences={manageOccurrences}
                />
              )}
            {(isFieldVisible(vehicle) || forceShowAllFields) && (
              <TrainField
                isRequired={vehicle === "REQUIRED" && !forceShowAllFields}
                occurrencePayload={occurrencePayload}
                setOccurrencePayload={setOccurrencePayload}
                handleVehicleLookUp={handleVehicleLookUp}
                searchingVehicle={searchingVehicle}
                vehicleSearchPayload={vehicleSearchPayload}
                setVehicleSearchPayload={setVehicleSearchPayload}
                manageOccurrences={manageOccurrences}
                selectedOperator={selectedOperator}
              />
            )}
            {(isFieldVisible(location) || forceShowAllFields) && (
              <LocationField
                handleFieldChange={handleFieldChange}
                isRequired={location === "REQUIRED" && !forceShowAllFields}
                locations={locations}
                occurrencePayload={occurrencePayload}
                setOccurrencePayload={setOccurrencePayload}
                trip={trip}
                linearAssetSelectionRequired={linearAssetSelectionRequired}
                manageOccurrences={manageOccurrences}
              />
            )}
            {(isFieldVisible(stationary_asset) || forceShowAllFields) && (
              <StationaryAssetsField
                isRequired={stationary_asset === "REQUIRED" && !forceShowAllFields}
                handleFieldChange={handleFieldChange}
                occurrencePayload={occurrencePayload}
                setOccurrencePayload={setOccurrencePayload}
                stationaryAssets={stationaryAssets}
                linearAssetSelectionRequired={linearAssetSelectionRequired}
                manageOccurrences={manageOccurrences}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}
