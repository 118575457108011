export function formatUnderscoreCasing(tripName: string): string {
  return tripName
    ?.replace(/_/g, " ")
    ?.replace(
      /\w\S*/g,
      (txt) => txt?.charAt(0)?.toUpperCase() + txt?.slice(1)?.toLowerCase()
    );
}

export function isPayloadValid(payload) {
  const checkValue = (value) => {
    if (value === null || value === undefined || value === "") {
      return false;
    }
    if (typeof value === "object" && !Array.isArray(value)) {
      return isPayloadValid(value);
    }
    if (Array.isArray(value)) {
      return value.every(checkValue);
    }
    return true;
  };

  return Object.values(payload).every(checkValue);
}

export function handlePotentialBooleanStrings(potentialBooleanString) {
  if (
    potentialBooleanString === "False" ||
    potentialBooleanString === "false" ||
    potentialBooleanString === "FALSE" ||
    potentialBooleanString === false
  ) {
    return false;
  } else return true;
}

export function findFirstAndLastVehicles(vehicle_formation_vehicles) {
  if (!vehicle_formation_vehicles || vehicle_formation_vehicles.length === 0) {
    return { firstVehicle: null, lastVehicle: null };
  }
  let firstVehicle = vehicle_formation_vehicles[0];
  let lastVehicle = vehicle_formation_vehicles[0];

  for (const vehicle_formation_vehicle of vehicle_formation_vehicles) {
    if (vehicle_formation_vehicle.sequence < firstVehicle.sequence) {
      firstVehicle = vehicle_formation_vehicle;
    }
    if (vehicle_formation_vehicle.sequence > lastVehicle.sequence) {
      lastVehicle = vehicle_formation_vehicle;
    }
  }

  return { firstVehicle, lastVehicle };
}

export function findLowestSequence(trip_locations) {
  const lowest = trip_locations.reduce((lowest, location) => {
    if (location.actual_location === true) {
      if (lowest === null || location?.sequence < lowest?.sequence) {
        return location;
      }
    }
    return lowest;
  }, null);

  return lowest.location.code;
};

export function findHighestSequence(trip_locations) {
  const highest = trip_locations.reduce((highest, location) => {
    if (location.actual_location === true) {
      if (highest === null || location?.sequence > highest?.sequence) {
        return location;
      }
    }
    return highest;
  }, null);

  return highest.location.code;
};
