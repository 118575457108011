import { DynamicFieldValue } from "../types/forms";

export const formatFieldValues = (fieldValuePayload: DynamicFieldValue) => {
    const formattedFieldValues = [];

    const fieldValueJson = JSON.parse(JSON.stringify(fieldValuePayload));

    for (const fieldName in fieldValueJson) {
        let value = fieldValueJson[fieldName];

        if (value && typeof value === "object" && "value" in value) {
            value = value.value;
        }

        if (value !== "" && value !== null) {
            formattedFieldValues.push({
                field: fieldName,
                value: value,
            });
        }
    }

    return formattedFieldValues;
};
