import { useQueryClient } from "@tanstack/react-query";
import { TripOccurrencePayload } from "../types/forms";

interface UsePostTripOccurrenceParams {
  operator_short_name: string;
  service_date: string;
  trip: any;
  setPostingOccurrence: (posting: boolean) => void;
  resetFormState: () => void;
  setOccurrenceDrawer: (open: boolean) => void;
  setTripOccurrenceType: (type: string) => void;
  toast: any;
  postTripOccurrence: (
    operator_short_name: string,
    service_date: string,
    trip_name: string,
    payload: any
  ) => Promise<any>;
  occurrenceVia: object | null;
}

export const usePostTripOccurrence = ({
  operator_short_name,
  service_date,
  trip,
  setPostingOccurrence,
  resetFormState,
  setOccurrenceDrawer,
  setTripOccurrenceType,
  toast,
  postTripOccurrence,
  occurrenceVia,
}: UsePostTripOccurrenceParams) => {
  const queryClient = useQueryClient();

  const postTripOccurrenceHandler = async (
    tripOccurrencePayload: TripOccurrencePayload
  ) => {
    const cleanObject = (obj: any) => {
      return Object.fromEntries(
        Object.entries(obj).filter(
          ([_, value]) => value !== "" && value !== null && value !== undefined
        )
      );
    };

    const cleanedPayload = {
      occurrence: cleanObject(tripOccurrencePayload.occurrence),
      trip_occurrence: cleanObject(tripOccurrencePayload.trip_occurrence),
    };

    if (
      occurrenceVia?.action === "originate" ||
      occurrenceVia?.action === "terminate" ||
      occurrenceVia?.action === "cancel"
    ) {
      cleanedPayload.trip_occurrence.trip_status_attribution = true;
      cleanedPayload.trip_occurrence.delay_minutes = null;
    }

    setPostingOccurrence(true);
    try {
      await postTripOccurrence(
        operator_short_name,
        service_date,
        trip.trip_name,
        cleanedPayload
      ).then((res) => {
        if (!res.error) {
          resetFormState();
          setOccurrenceDrawer(false);
          setTripOccurrenceType("new");
          toast.success(`Trip ${trip?.trip_name} saved`);
          queryClient.setQueryData(
            ["trip", operator_short_name, service_date, trip.trip_name],
            (oldData) => {
              return {
                ...oldData,
                trip_occurrences: [res, ...trip.trip_occurrences],
              };
            }
          );
          queryClient.setQueryData(
            ["occurrences_operator_service_day", operator_short_name, service_date],
            (oldData) => {
              return [res, ...oldData];
            }
          );
        }
        setPostingOccurrence(false);
      });
    } catch (error) {
      console.log(error);
      setPostingOccurrence(false);
    }
  };

  return { postTripOccurrenceHandler };
};
