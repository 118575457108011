import { useEffect, useState } from "react";
import LoadingSpinner from "../../icons/LoadingSpinner";
import SingleSelect from "../SingleSelect";
import TextField from "../TextField";
import TextArea from "../TextArea";
import DateTimePicker from "../DateTimePicker";
import { useQueries } from "@tanstack/react-query";
import useFetchFunctions from "../../services/trips";
import DynamicOccurrenceCauseFormFields from "../trip-detail/trip-occurrences/DynamicOccurrenceCauseFormFields";
import DynamicFormFields from "../trip-detail/trip-occurrences/DynamicFormFields";
import { formatInTimeZone } from "date-fns-tz";
import { formatFieldValues } from "../../utils/formatters";
import { FieldValue } from "../../types/forms";
import toast from "react-hot-toast";

const defaultAddOccurrencePayload = {
  title: "",
  start: "",
  end: "",
  occurrence_cause: "",
  responsible_party: "",
  comments: "",
};

const isValidPayload = (payload) => {
  // Filter out linear_asset_start and linear_asset_end from the payload values
  // const filteredPayloadValues = Object.entries(payload)
  //   .filter(([key]) => key !== "linear_asset_start" && key !== "linear_asset_end")
  //   .map(([, value]) => value);

  // // Check if all remaining values in the payload are not empty
  // const allValuesNotEmpty = filteredPayloadValues.every((value) => value !== "");

  // if (!allValuesNotEmpty) {
  //   return false;
  // }

  // Get the required fields from the occurrence cause in the payload
  const occurrenceCause = payload?.occurrence_cause?.value;
  const requiredFields = ["title", "start", "end", "occurrence_cause", "responsible_party", "comments"];
  if (occurrenceCause) {
    for (const [key, value] of Object.entries(occurrenceCause)) {
      if (value === "REQUIRED") {
        requiredFields.push(key);
      }
    }
  }

  // Check if all required fields are present and not empty in the payload
  const allRequiredFieldsPresent = requiredFields.every((field) => {
    if (field === "linear_asset") {
      return (
        payload["linear_asset_id"] !== undefined &&
        payload["linear_asset_id"] !== "" &&
        payload["linear_asset_start"] !== undefined &&
        payload["linear_asset_start"] !== "" &&
        payload["linear_asset_end"] !== undefined &&
        payload["linear_asset_end"] !== ""
      );
    } else {
      return payload[field] !== undefined && payload[field] !== "";
    }
  });

  return allRequiredFieldsPresent;
};

const formatStationaryAssets = (stationaryAssets) => {
  const formattedArray = [];

  // Process locations
  stationaryAssets?.data?.locations?.forEach((location) => {
    const { id, name, stationary_assets } = location;
    if (stationary_assets && stationary_assets.length > 0) {
      formattedArray.push({ id, location_name: name, assets: stationary_assets });
    }
  });

  // Process location_links
  stationaryAssets?.data?.location_links?.forEach((link) => {
    const { id, group, stationary_assets } = link;
    if (stationary_assets && stationary_assets.length > 0) {
      formattedArray.push({ id, location_name: group, assets: stationary_assets });
    }
  });

  return { data: formattedArray, isLoading: stationaryAssets.isLoading };
};

export default function UnusualOccurrenceFlyout({
  unusualOccurrenceDrawerIsOpen,
  setUnusualOccurrenceDrawerIsOpen,
  occurrenceToBeEditedProp,
  setOccurrenceToBeEditedProp,
  operator_short_name,
  fetchingForEdit,
}) {
  const [addOccurrencePayload, setAddOccurrencePayload] = useState(
    defaultAddOccurrencePayload
  );
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [occurrenceCauseFields, setOccurrenceCauseFields] = useState([]);
  const [vehicleSearchPayload, setVehicleSearchPayload] = useState("");
  const [searchingVehicle, setSearchingVehicle] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [occurrenceCauseValuesPayload, setOccurrenceCauseValuesPayload] = useState(
    {}
  );

  //edit mode states
  const [isEditMode, setIsEditMode] = useState(false);
  const [originalValues, setOriginalValues] = useState({});
  const [occurrenceToBeEdited, setOccurrenceToBeEdited] = useState({});
  const [occurrenceCauseValuesForEdit, setOccurrenceCauseValuesForEdit] = useState(
    {}
  );
  const [originalOccurrenceCauseValues, setOriginalOccurrenceCauseValues] = useState(
    {}
  );
  const [loadingEditState, setLoadingEditState] = useState(false);

  useEffect(() => {
    if (!isEditMode) {
      const now = new Date();
      const startDatetime = formatInTimeZone(now, 'America/New_York', "yyyy-MM-dd'T'HH:mm:ssXXX");
      const endOfDay = new Date(now);
      endOfDay.setHours(23, 59, 59, 999);
      const endDatetime = formatInTimeZone(endOfDay, 'America/New_York', "yyyy-MM-dd'T'HH:mm:ssXXX");

      setAddOccurrencePayload((prev) => ({
        ...prev,
        start: startDatetime,
        end: endDatetime,
      }));
    }
  }, [isEditMode, setIsEditMode])

  useEffect(() => {
    if (
      occurrenceToBeEditedProp &&
      !loadingEditState &&
      Object.keys(occurrenceToBeEdited).length === 0
    ) {
      setIsEditMode(true);
      const dataToBeEditedJson = JSON.parse(
        JSON.stringify(occurrenceToBeEditedProp)
      );

      const {
        end_datetime,
        start_datetime,
        occurrence_cause,
        occurrence_type,
        operator,
        responsible_party_name,
        title,
        description,
        linear_asset,
        linear_asset_start,
        linear_asset_end,
        stationary_asset,
        vehicle,
        location,
        responsible_party,
      } = dataToBeEditedJson;

      let currentCause;
      if (Array.isArray(operatorOccurrenceCauses?.data)) {
        currentCause = operatorOccurrenceCauses.data.find(
          (cause) => cause.occurrence_cause.name === occurrence_cause?.name
        );
      }

      let currentOperator;
      if (Array.isArray(operators?.data)) {
        currentOperator = operators.data.find((x) => x.name === operator?.name);
      }

      const formattedOccurrence = {};

      formattedOccurrence["title"] = title;
      formattedOccurrence["start"] = start_datetime
        ? formatInTimeZone(start_datetime, "America/New_York", "yyyy-MM-dd hh:mm:ss")
        : "";
      formattedOccurrence["end"] = end_datetime
        ? formatInTimeZone(end_datetime, "America/New_York", "yyyy-MM-dd hh:mm:ss")
        : "";
      formattedOccurrence["occurrence_type"] = occurrence_type;
      formattedOccurrence["responsible_party"] = {
        value: responsible_party_name,
        label: responsible_party_name,
      };
      formattedOccurrence["comments"] = description;

      if (currentCause) {
        formattedOccurrence["occurrence_cause"] = {
          value: currentCause.occurrence_cause,
          label: currentCause.occurrence_cause.name,
        };
      }

      formattedOccurrence["vehicle"] = vehicle;
      formattedOccurrence["responsible_party"] = {
        value: responsible_party?.id,
        label: responsible_party?.name,
      };
      formattedOccurrence["stationary_asset"] = stationary_asset;
      formattedOccurrence["linear_asset_id"] = {
        value: linear_asset,
        label: linear_asset,
      };
      formattedOccurrence["linear_asset_start"] = linear_asset_start;
      formattedOccurrence["linear_asset_end"] = linear_asset_end;
      formattedOccurrence["location"] = {
        value: location?.id,
        label: location?.name,
      };

      const formattedOccurrenceCauseValuesPayload = {};

      if (dataToBeEditedJson?.occurrence_cause_values) {
        dataToBeEditedJson?.occurrence_cause_values.forEach((value) => {
          if (value.occurrence_cause_field.lookup_list_items.length > 0) {
            formattedOccurrenceCauseValuesPayload[value.occurrence_cause_field.name] = {
              value: value.value,
              label: value.value,
            };
          } else {
            formattedOccurrenceCauseValuesPayload[value.occurrence_cause_field.name] =
              value.value;
          }
        });
      }

      setSelectedOperator({
        value: currentOperator,
        label: currentOperator?.name,
      });
      setOriginalValues(formattedOccurrence);
      setAddOccurrencePayload(formattedOccurrence);
      setOccurrenceCauseFields(currentCause?.occurrence_cause_fields);
      setOccurrenceCauseValuesPayload(formattedOccurrenceCauseValuesPayload);
      setOriginalOccurrenceCauseValues(formattedOccurrenceCauseValuesPayload);
    }
  }, [occurrenceToBeEditedProp, loadingEditState]);

  const handleCloseForm = () => {
    setUnusualOccurrenceDrawerIsOpen(false);
    setAddOccurrencePayload(defaultAddOccurrencePayload);
    setSelectedOperator(null);
    setOccurrenceCauseFields([]);
    setVehicleSearchPayload("");
    setSearchingVehicle(false);
    setIsSaving(false);
    setOccurrenceCauseValuesPayload({});
    setIsEditMode(false);
    setOriginalValues({});
    setOccurrenceToBeEdited({});
    setOccurrenceCauseValuesForEdit({});
    setOriginalOccurrenceCauseValues({});
    setLoadingEditState(false);
    if (occurrenceToBeEditedProp) {
      setOccurrenceToBeEditedProp(null);
    }
  };

  const {
    fetchOperators,
    fetchOperatorOccurrenceCauses,
    fetchResponsibleParties,
    postOccurrence,
    fetchLinearAssetsByTransportationMode,
    fetchStationaryAssetsByLinearAsset,
    fetchLocationsByLinearAsset,
    fetchVehicle,
    editOccurrence,
  } = useFetchFunctions();

  const results = useQueries({
    queries: [
      {
        queryKey: ["operators"],
        queryFn: () => fetchOperators(),
        staleTime: 1000 * 10,
        enabled: unusualOccurrenceDrawerIsOpen,
      },
      {
        queryKey: ["responsible_parties"],
        queryFn: () => fetchResponsibleParties(),
        staleTime: 1000 * 10,
        enabled: unusualOccurrenceDrawerIsOpen,
      },
      {
        queryKey: [
          "operator_occurrence_causes",
          operator_short_name || selectedOperator?.value?.short_name,
        ],
        queryFn: () =>
          operator_short_name
            ? fetchOperatorOccurrenceCauses(operator_short_name)
            : fetchOperatorOccurrenceCauses(selectedOperator?.value?.short_name),
        enabled: !!operator_short_name || !!selectedOperator?.value?.short_name,
        staleTime: 0,
      },
      {
        queryKey: [
          "linear_assets",
          selectedOperator?.value?.transportation_mode?.abbreviation,
        ],
        queryFn: () =>
          fetchLinearAssetsByTransportationMode(
            selectedOperator?.value?.transportation_mode?.abbreviation
          ),
        staleTime: 1000 * 10,
        enabled:
          unusualOccurrenceDrawerIsOpen &&
          !!selectedOperator?.value?.transportation_mode?.abbreviation,
      },
      {
        queryKey: [
          "stationary_assets",
          addOccurrencePayload?.occurrence_cause?.value?.stationary_asset_type,
        ],
        queryFn: () =>
          fetchStationaryAssetsByLinearAsset(
            addOccurrencePayload?.linear_asset_id?.value?.id,
            addOccurrencePayload?.occurrence_cause?.value?.stationary_asset_type
          ),
        staleTime: 0,
        enabled:
          !!addOccurrencePayload?.linear_asset_id?.value?.id &&
          !!addOccurrencePayload?.occurrence_cause?.value?.stationary_asset &&
          (addOccurrencePayload?.occurrence_cause?.value?.stationary_asset ===
            "OPTIONAL" ||
            addOccurrencePayload?.occurrence_cause?.value?.stationary_asset ===
            "REQUIRED"),
      },
      {
        queryKey: ["locations", addOccurrencePayload?.linear_asset_id?.value?.id],
        queryFn: () =>
          fetchLocationsByLinearAsset(
            addOccurrencePayload?.linear_asset_id?.value?.id
          ),
        staleTime: 0,
        enabled:
          !!addOccurrencePayload?.linear_asset_id?.value?.id &&
          (addOccurrencePayload?.occurrence_cause?.value?.location === "OPTIONAL" ||
            addOccurrencePayload?.occurrence_cause?.value?.location === "REQUIRED"),
      },
    ],
  });

  const operators = results[0];
  const responsibleParties = results[1];
  const operatorOccurrenceCauses = results[2];
  const linearAssets = results[3];
  const stationaryAssets = results[4];
  const locations = results[5];

  useEffect(() => {
    if (!selectedOperator && !operators.isLoading && operators.data && !isEditMode) {
      setSelectedOperator({ value: operators.data[0], label: operators.data[0].name });
    }
  }, [selectedOperator, operators.isLoading]);

  const handleFieldChange = (field, value) => {
    const originalValue = originalValues[field];

    const isValueChanged =
      typeof value === "boolean" ? value !== originalValue : value !== originalValue;

    setAddOccurrencePayload((payload) => ({
      ...payload,
      [field]: value,
    }));

    if (isEditMode) {
      if (isValueChanged) {
        setOccurrenceToBeEdited((payload) => ({
          ...payload,
          [field]: value,
        }));
      } else {
        setOccurrenceToBeEdited((payload) => {
          const updatedEdited = { ...payload };
          delete updatedEdited[field];
          return updatedEdited;
        });
      }
    }
  };

  const handleValuesFieldChange = (
    field,
    value,
    setPayload,
    setPayloadForEdit,
    originalValues
  ) => {
    setPayload((prev) => ({
      ...prev,
      [field]: value,
    }));

    if (isEditMode) {
      if (JSON.stringify(value) !== JSON.stringify(originalValues[field])) {
        setPayloadForEdit((prev) => ({
          ...prev,
          [field]: value,
        }));
      } else {
        setPayloadForEdit((prev) => {
          const updatedEdited = { ...prev };
          delete updatedEdited[field];
          return updatedEdited;
        });
      }
    }
  };

  const handleSetOccurrenceCauseValues = (field, value) => {
    handleValuesFieldChange(
      field,
      value,
      setOccurrenceCauseValuesPayload,
      setOccurrenceCauseValuesForEdit,
      originalOccurrenceCauseValues
    );
  };

  const handleVehicleLookUp = async () => {
    setSearchingVehicle(true);

    try {
      await fetchVehicle(
        selectedOperator?.value?.short_name,
        vehicleSearchPayload.carrier_code?.value,
        vehicleSearchPayload.serial_number
      ).then((res) => {
        if (!res.error) {
          // if (isEditMode) {
          //   setOccurrenceToBeEdited((prevOccurrenceToBeEdited) => ({
          //     ...prevOccurrenceToBeEdited,
          //     vehicle: res,
          //   }));
          // } else {
          setAddOccurrencePayload((prevOccurrencePayload) => ({
            ...prevOccurrencePayload,
            vehicle: res,
          }));
          // }
        }
        setSearchingVehicle(false);
      });
    } catch (error) {
      console.log("Error:", error);
      setSearchingVehicle(false);
    }
  };

  const handleAddOccurrence = async () => {
    const addOccurrenceJson = JSON.parse(JSON.stringify(addOccurrencePayload));

    const formattedOccurrenceCauseValues: FieldValue[] = formatFieldValues(
      occurrenceCauseValuesPayload
    );

    const formattedPayload = {
      primary: true,
      start_datetime: addOccurrenceJson?.start,
      end_datetime: addOccurrenceJson?.end,
      title: addOccurrenceJson?.title,
      field_report: false,
      linear_asset_id: addOccurrenceJson?.linear_asset_id?.value?.id,
      linear_asset_start: addOccurrenceJson?.linear_asset_start,
      linear_asset_end: addOccurrenceJson?.linear_asset_end,
      description: addOccurrenceJson?.comments,
      occurrence_cause_id: addOccurrenceJson?.occurrence_cause?.value?.id,
      responsible_party_id: addOccurrenceJson?.responsible_party?.value,
      location_id: addOccurrenceJson?.location?.value,
      stationary_asset_id: addOccurrenceJson?.stationary_asset?.value,
      vehicle_id: addOccurrenceJson?.vehicle?.id,
      values: formattedOccurrenceCauseValues || [],
    };

    if (addOccurrenceJson?.occurrence_cause?.value?.linear_asset === "NOT SHOWN") {
      delete formattedPayload.linear_asset_id;
      delete formattedPayload.linear_asset_start;
      delete formattedPayload.linear_asset_end;
    }

    const cleanedPayload = Object.fromEntries(
      Object.entries(formattedPayload).filter(
        ([_, value]) => value !== "" && value !== null && value !== undefined
      )
    );

    setIsSaving(true);
    try {
      await postOccurrence(selectedOperator?.value?.short_name, cleanedPayload).then(
        (res) => {
          if (!res.error) {
            handleCloseForm();
            toast.success(`Occurrence saved`);
          }
          setIsSaving(false);
        }
      );
    } catch (error) {
      console.log(error);
      setIsSaving(false);
    }
  };

  const handleEditOccurrence = async () => {
    const editedOccurrenceJson = JSON.parse(JSON.stringify(occurrenceToBeEdited));

    let formattedOccurrenceCauseValuesPayload: FieldValue[] = formatFieldValues(
      occurrenceCauseValuesForEdit
    );

    const hasCauseFields = Object.keys(occurrenceCauseFields).length > 0;

    const hasOccurrenceCauseValues =
      originalValues?.occurrence_cause_values?.length > 0;

    if (!hasCauseFields && hasOccurrenceCauseValues) {
      formattedOccurrenceCauseValuesPayload = [];
    }

    const formattedPayload = {
      start_datetime: editedOccurrenceJson?.start,
      end_datetime: editedOccurrenceJson?.end,
      title: editedOccurrenceJson?.title,
      linear_asset_id: editedOccurrenceJson?.linear_asset_id?.value?.id,
      linear_asset_start:
        editedOccurrenceJson?.linear_asset_id?.value?.start_measurement,
      linear_asset_end:
        editedOccurrenceJson?.linear_asset_id?.value?.end_measurement,
      description: editedOccurrenceJson?.comments,
      occurrence_cause_id: editedOccurrenceJson?.occurrence_cause?.value?.id,
      responsible_party_id: editedOccurrenceJson?.responsible_party?.value,
      location_id: editedOccurrenceJson?.location?.value,
      stationary_asset_id: editedOccurrenceJson?.stationary_asset?.value?.id,
      vehicle_id: editedOccurrenceJson?.vehicle?.id,
      values: formattedOccurrenceCauseValuesPayload,
    };

    if (
      editedOccurrenceJson?.occurrence_cause?.value?.linear_asset === "NOT SHOWN"
    ) {
      delete formattedPayload.linear_asset_id;
      delete formattedPayload.linear_asset_start;
      delete formattedPayload.linear_asset_end;
    }

    const cleanedPayload = Object.fromEntries(
      Object.entries(formattedPayload).filter(
        ([_, value]) => value !== "" && value !== null && value !== undefined
      )
    );

    setIsSaving(true);
    try {
      await editOccurrence(
        selectedOperator?.value?.short_name,
        occurrenceToBeEditedProp.occurrence_id,
        cleanedPayload
      ).then((res) => {
        if (!res.error) {
          handleCloseForm();
          toast.success(`Occurrence saved`);
        }
        setIsSaving(false);
      });
    } catch (error) {
      console.log(error);
      setIsSaving(false);
    }
  };

  useEffect(() => {
    const anyLoading = results.some((result) => result.isLoading);
    setLoadingEditState(anyLoading);
  }, [results]);

  const areFieldsClean = () => {
    if (!isEditMode) return false;

    return (
      Object.keys(occurrenceCauseValuesForEdit).length === 0 &&
      Object.keys(occurrenceToBeEdited).length === 0
    );
  };

  useEffect(() => {
    if (addOccurrencePayload?.location) {
      setAddOccurrencePayload((prevState) => ({
        ...prevState,
        location: "",
      }));
    }
  }, [addOccurrencePayload?.linear_asset_id]);

  return (
    <div className="drawer-side z-10">
      <label htmlFor="add-occurrence-drawer" className="drawer-overlay"></label>
      <div className="min-h-full w-full md:w-1/2 xl:w-1/3 bg-base-100 p-5 text-base-content prose">
        <h2>Unusual Occurrence Details</h2>
        <label
          htmlFor="add-occurrence-drawer"
          onClick={() => {
            handleCloseForm();
          }}
          className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
        >
          ✕
        </label>
        {fetchingForEdit ? (
          <>
            <div className="w-full h-screen skeleton"></div>
          </>
        ) : (
          <>
            <div className="form-control">
              <label className="label">
                <span className="label-text">
                  Operator <span className="text-error">*</span>
                </span>
              </label>
              <SingleSelect
                placeholder="Pick one"
                isDisabled={operators.isLoading || operators.data?.length <= 1}
                isLoading={operators?.isLoading}
                value={selectedOperator}
                handleOnChange={(operator) => setSelectedOperator(operator)}
                options={
                  operators?.data &&
                  Array.isArray(operators?.data) &&
                  operators.data?.map((operator) => {
                    return { value: operator, label: operator.name };
                  })
                }
              />
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text">
                  Title <span className="text-error">*</span>
                </span>
              </label>
              <TextField
                placeholder="Enter a title"
                value={addOccurrencePayload.title}
                handleOnChange={(title) =>
                  handleFieldChange("title", title.target.value)
                }
              />
            </div>

            <div className="flex justify-between">
              <div>
                <label className="label">
                  <span className="label-text">
                    Start <span className="text-error">*</span>
                  </span>
                </label>
                <DateTimePicker
                  value={addOccurrencePayload.start}
                  handleOnChange={(date) => handleFieldChange("start", date)}
                  className="input input-bordered input-sm lg:input-md w-full"
                />
              </div>

              <div>
                <label className="label">
                  <span className="label-text">
                    End <span className="text-error">*</span>
                  </span>
                </label>
                <DateTimePicker
                  value={addOccurrencePayload.end}
                  handleOnChange={(date) => handleFieldChange("end", date)}
                  className="input input-bordered input-sm lg:input-md w-full"
                />
              </div>
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text">
                  Occurrence Cause <span className="text-error">*</span>
                </span>
              </label>
              <SingleSelect
                placeholder="Pick one"
                isDisabled={operatorOccurrenceCauses?.isLoading || !selectedOperator}
                isLoading={operatorOccurrenceCauses?.isLoading}
                useFormatGroupLabel={true}
                value={addOccurrencePayload.occurrence_cause}
                handleOnChange={(occurrence_cause) => {
                  setAddOccurrencePayload((prevState) => ({
                    ...prevState,
                    stationary_asset: "",
                    linear_asset_id: "",
                    linear_asset_start: "",
                    linear_asset_end: "",
                    vehicle: "",
                  }));
                  setOccurrenceCauseFields(
                    occurrence_cause.value.occurrence_cause_fields
                  );
                  handleFieldChange("occurrence_cause", {
                    value: occurrence_cause.value.occurrence_cause,
                    label: occurrence_cause.label,
                  });
                }}
                options={
                  Array.isArray(operatorOccurrenceCauses?.data) &&
                  Object.values(
                    operatorOccurrenceCauses?.data?.reduce((acc, item) => {
                      const type = item.occurrence_cause.type;
                      if (!acc[type]) {
                        acc[type] = {
                          label: type,
                          options: [],
                        };
                      }
                      acc[type].options.push({
                        value: item,
                        label: item.occurrence_cause.name,
                      });
                      return acc;
                    }, {})
                  )
                }
              />
            </div>

            {loadingEditState ? (
              <div className="skeleton min-h-40 col-span-1 mt-4"></div>
            ) : (
              <DynamicOccurrenceCauseFormFields
                selectedOperator={selectedOperator}
                locations={locations}
                occurrencePayload={addOccurrencePayload}
                setOccurrencePayload={setAddOccurrencePayload}
                stationaryAssets={
                  stationaryAssets && formatStationaryAssets(stationaryAssets)
                }
                handleFieldChange={handleFieldChange}
                vehicleSearchPayload={vehicleSearchPayload}
                setVehicleSearchPayload={setVehicleSearchPayload}
                searchingVehicle={searchingVehicle}
                handleVehicleLookUp={handleVehicleLookUp}
                occurrenceCause={
                  addOccurrencePayload?.occurrence_cause &&
                  addOccurrencePayload?.occurrence_cause
                }
                linearAssetSelectionRequired={true}
                linearAssets={linearAssets}
                forceShowLinearAssets={
                  addOccurrencePayload?.occurrence_cause?.value?.stationary_asset ===
                  "REQUIRED" ||
                  addOccurrencePayload?.occurrence_cause?.value?.stationary_asset ===
                  "OPTIONAL" ||
                  addOccurrencePayload?.occurrence_cause?.value?.location ===
                  "REQUIRED" ||
                  addOccurrencePayload?.occurrence_cause?.value?.location ===
                  "OPTIONAL"
                }
              />
            )}

            <DynamicFormFields
              fields={occurrenceCauseFields}
              payload={occurrenceCauseValuesPayload}
              handleFieldChange={handleSetOccurrenceCauseValues}
            />

            <div className="form-control">
              <label className="label">
                <span className="label-text">
                  Responsible Party <span className="text-error">*</span>
                </span>
              </label>
              <SingleSelect
                placeholder="Pick one"
                isDisabled={responsibleParties?.isLoading}
                isLoading={responsibleParties?.isLoading}
                value={addOccurrencePayload.responsible_party}
                handleOnChange={(responsible_party) =>
                  handleFieldChange("responsible_party", responsible_party)
                }
                options={
                  responsibleParties?.data &&
                  Array.isArray(responsibleParties?.data) &&
                  responsibleParties.data?.map((party) => {
                    return { value: party.id, label: party.name };
                  })
                }
              />
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text">
                  Comments
                  <span className="text-error"> *</span>
                </span>
              </label>
              <TextArea
                value={addOccurrencePayload.comments}
                handleOnChange={(event) =>
                  handleFieldChange("comments", event.target.value)
                }
              />
            </div>

            <div className="flex gap-2 mt-5">
              <button
                onClick={() => {
                  handleCloseForm();
                }}
                className="btn btn-primary btn-outline"
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={(event) => {
                  event.preventDefault();
                  if (isEditMode) {
                    handleEditOccurrence();
                  } else {
                    handleAddOccurrence();
                  }
                }}
                disabled={
                  isSaving ||
                  (!isEditMode && !isValidPayload(addOccurrencePayload)) ||
                  areFieldsClean()
                }
              >
                Save
                {isSaving && <LoadingSpinner />}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
