import TripSearchTable from "./TripSearchTable";
import SingleSelect from "../SingleSelect";
import DateRangePicker from "../DateRangePicker";
import TextField from "../TextField";
import LoadingSpinner from "../../icons/LoadingSpinner";
import YesNoCheckbox from "../YesNoCheckbox";
import { useState, useEffect, useRef, useContext } from "react";
import { useFetchFunctions } from "../../services/trips";
import "react-datepicker/dist/react-datepicker.css";
import { useQueries } from "@tanstack/react-query";
import { formatInTimeZone, format } from "date-fns-tz";
import { TripSearchPayload } from "../../types/trips";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/pro-regular-svg-icons";
import Pagination from "../Pagination";
import { SearchResultsContext } from "../../utils/Contexts";
import AdvancedSearchFilters from "./AdvancedSearchFilters";
import { NavigationContext } from "../../utils/Contexts";

export async function loader() {
  return {};
}

const defaultTripSearchPayload: TripSearchPayload = {
  start_date: formatInTimeZone(new Date(), "America/New_York", "P"),
  end_date: formatInTimeZone(new Date(), "America/New_York", "P"),
  trip_name: "",
  schedule_type: "",
  signed_off: undefined,
  serial_number: "",
  carrier_code: "",
  trip_values: [],
  page: "1",
  page_size: "50",
  sort: "ASC",
  sort_by: "service_date",
  sort_dynamic: undefined,
};

export default function TripSearch() {
  const [searchPayload, setSearchPayload] = useState<TripSearchPayload>(
    defaultTripSearchPayload
  );
  const [selectedOperator, setSelectedOperator] = useState("");
  const [loadingSearchResults, setLoadingSearchResults] = useState(false);
  const [searchResults, setSearchResults] = useState({});
  const tableRef = useRef(null);

  const { currentSearchResultsContext, setCurrentSearchResultsContext } =
    useContext(SearchResultsContext);
  const { currentNavigationContext, setCurrentNavigationContext } =
    useContext(NavigationContext);

  useEffect(() => {
    setCurrentNavigationContext("trip-search");
  }, []);

  const { fetchOperators, fetchScheduleTypes, fetchOperatorTripFields, fetchTrips } =
    useFetchFunctions();

  const results = useQueries({
    queries: [
      {
        queryKey: ["operators"],
        queryFn: () => fetchOperators(),
        staleTime: 1000 * 10,
      },
      {
        queryKey: ["schedule_types"],
        queryFn: () => fetchScheduleTypes(),
        staleTime: 1000 * 10,
      },
      {
        queryKey: ["operator_trip_fields", selectedOperator?.value?.short_name],
        queryFn: () => fetchOperatorTripFields(selectedOperator?.value?.short_name),
        enabled: !!selectedOperator,
        staleTime: 1000 * 10,
      },
    ],
  });

  const operators = results[0];
  const scheduleTypes = results[1];
  const operatorTripFields = results[2];

  const handleChangeDate = (start_date: Date, end_date: Date) => {
    setSearchPayload({
      ...searchPayload,
      start_date,
      end_date,
    });
  };

  const handleNewTripValue = (
    field_name: string,
    newValue: string | boolean | string[] | undefined
  ) => {
    if (newValue === "") {
      newValue = undefined;
    }
    const newTripValues = [
      ...searchPayload?.trip_values?.filter(
        (trip_value) => trip_value?.field_name !== field_name
      ),
      {
        field_name,
        value: newValue,
      },
    ];
    setSearchPayload({
      ...searchPayload,
      trip_values: newTripValues,
    });
  };

  const handleSearch = async (
    operator_short_name: string,
    search_payload: TripSearchPayload
  ) => {
    const formattedTripValues = search_payload?.trip_values
      .filter(
        (x) =>
          x?.value !== undefined &&
          (!Array.isArray(x?.value) || x?.value?.length !== 0)
      )
      .map((tripValue) => ({
        ...tripValue,
        value: Array.isArray(tripValue?.value)
          ? tripValue?.value.map((x) => x?.value)
          : tripValue?.value,
      }));
    const formattedPayload = {
      ...search_payload,
      start_date: format(search_payload.start_date, "yyyy-MM-dd"),
      end_date: format(search_payload.end_date, "yyyy-MM-dd"),
      trip_values: formattedTripValues,
      schedule_type: search_payload?.schedule_type?.value || "",
      carrier_code: search_payload?.carrier_code?.value || "",
    };
    setLoadingSearchResults(true);
    try {
      await fetchTrips(operator_short_name, formattedPayload).then((res) => {
        setSearchResults(res);
        setLoadingSearchResults(false);
        setCurrentSearchResultsContext(
          JSON.stringify({
            data: res,
            search_payload: search_payload,
            selected_operator: selectedOperator,
          })
        );
      });
    } catch (error) {
      console.log(error);
      setLoadingSearchResults(false);
    }
  };

  const handleResetSearch = () => {
    setSelectedOperator(null);
    setSearchPayload(defaultTripSearchPayload);
    setLoadingSearchResults(false);
    setSearchResults({});
  };

  const handleChangePage = (pageNumber, resultsPerPage) => {
    console.log(pageNumber);
    setSearchPayload({
      ...searchPayload,
      page: pageNumber,
      page_size: resultsPerPage,
    });
    handleSearch(selectedOperator.value?.short_name, {
      ...searchPayload,
      page: pageNumber,
      page_size: resultsPerPage,
    });
  };

  const handleChangeSort = (sort, sort_by, isDynamicSort) => {
    setSearchPayload({
      ...searchPayload,
      sort: sort,
      sort_by: sort_by,
      sort_dynamic: isDynamicSort,
    });
    handleSearch(selectedOperator.value?.short_name, {
      ...searchPayload,
      sort: sort,
      sort_by: sort_by,
      sort_dynamic: isDynamicSort,
    });
  };

  const handleChangeOperator = (operator) => {
    setSelectedOperator(operator)
    if (!operator) {
      setSearchPayload((prev) => ({
        ...prev,
        carrier_code: "",
      }));
    }
    if (operator?.value?.vehicle_carrier_codes?.length === 1) {
      setSearchPayload((prev) => ({
        ...prev,
        carrier_code: {
          label: operator.value.vehicle_carrier_codes[0],
          value: operator.value.vehicle_carrier_codes[0],
        },
      }));
    }
  }

  useEffect(() => {
    if (operators?.data?.length === 1) {
      const operator = operators.data[0];
      setSelectedOperator({ value: operator, label: operator.name });
    }
  }, [operators?.data, operatorTripFields?.data, operatorTripFields?.isLoading]);

  useEffect(() => {
    const previousSearchCache = currentSearchResultsContext;

    if (previousSearchCache) {
      try {
        const parsedPreviousSearch = JSON.parse(previousSearchCache);
        setSearchResults(parsedPreviousSearch.data);
        setSearchPayload(parsedPreviousSearch.search_payload);
        setSelectedOperator(parsedPreviousSearch.selected_operator);

        const scrollWithDelay = setTimeout(() => {
          if (tableRef.current) {
            tableRef.current.scrollIntoView({
              behavior: "smooth",
            });
          }
        }, 200);

        return () => clearTimeout(scrollWithDelay);
      } catch (error) {
        console.error("Error parsing cached results:", error);
      }
    }
  }, [operators?.data, scheduleTypes?.data]);

  return (
    <div>
      <div className="page-header bg-info lg:flex justify-between px-4 py-5">
        <div className="md:flex gap-4 items-center prose text-white">
          <h1 className="flex gap-3 items-center m-0 text-2xl lg:text-4xl text-white">
            Trip Search
          </h1>
        </div>
        <div className="breadcrumbs text-white hidden md:block">
          <ul>
            <li className="text-white/50">Trip Search</li>
          </ul>
        </div>
      </div>

      <div className="p-5">
        <div className="w-full">
          <div className="card card-compact bg-base-100 border border-base-300 prose">
            <div className="card-body gap-4">
              <div className="flex md:gap-4 flex-col md:flex-row md:items-center">
                <div className="mb-2 md:w-32 md:mb-0">
                  <label className="font-bold">Service Date</label>
                </div>
                <DateRangePicker
                  startDate={searchPayload.start_date}
                  endDate={searchPayload.end_date}
                  handleChangeDate={handleChangeDate}
                />
              </div>
              <div className="flex md:gap-4 flex-col md:flex-row md:items-center">
                <div className="mb-2 md:w-32 md:mb-0">
                  <label className="font-bold">Operator</label>
                </div>
                <SingleSelect
                  placeholder={"Select operator..."}
                  value={selectedOperator}
                  isDisabled={
                    operators?.data?.length <= 1 || !Array.isArray(operators?.data)
                  }
                  isLoading={operators.isLoading}
                  handleOnChange={(o) => handleChangeOperator(o)}
                  options={
                    operators &&
                    Array.isArray(operators?.data) &&
                    operators?.data?.map((operator) => {
                      return { value: operator, label: operator.name };
                    })
                  }
                />
              </div>
              <div className="flex md:gap-4 flex-col md:flex-row md:items-center">
                <div className="mb-2 md:w-32 md:mb-0">
                  <label className="font-bold">Trip</label>
                </div>
                <div className="flex gap-3 flex-wrap">
                  <TextField
                    className="w-20"
                    placeholder={"Trip"}
                    value={searchPayload.trip_name}
                    handleOnChange={(event) =>
                      setSearchPayload({
                        ...searchPayload,
                        trip_name: event.target.value,
                      })
                    }
                  />
                  <SingleSelect
                    placeholder={"Schedule type"}
                    value={searchPayload.schedule_type}
                    isLoading={scheduleTypes.isLoading}
                    handleOnChange={(schedule_type) =>
                      setSearchPayload({ ...searchPayload, schedule_type })
                    }
                    options={
                      scheduleTypes &&
                      Array.isArray(scheduleTypes?.data) &&
                      scheduleTypes?.data?.map((schedule) => {
                        return { value: schedule.id, label: schedule.abbreviation };
                      })
                    }
                  />
                </div>
              </div>

              <div className="flex md:gap-4 flex-col md:flex-row md:items-center">
                <div className="mb-2 md:w-32 md:mb-0">
                  <label className="font-bold">Vehicle</label>
                </div>
                <div className="join">
                  <div className="join-item w-full">
                    <SingleSelect
                      isDisabled={loadingSearchResults || !selectedOperator || selectedOperator?.value?.vehicle_carrier_codes?.length <= 1}
                      placeholder={"Carrier Code"}
                      value={searchPayload.carrier_code}
                      options={selectedOperator?.value?.vehicle_carrier_codes?.map((carrier_code) => ({
                        label: carrier_code,
                        value: carrier_code,
                      }))}
                      handleOnChange={(selectedOption) =>
                        setSearchPayload({
                          ...searchPayload,
                          carrier_code: selectedOption,
                        })
                      }
                    />
                  </div>
                  <TextField
                    className="join-item w-full"
                    placeholder={"Serial #"}
                    value={searchPayload.serial_number}
                    handleOnChange={(event) =>
                      setSearchPayload({
                        ...searchPayload,
                        serial_number: event.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="flex items-center">
                <YesNoCheckbox
                  name={"Signed Off"}
                  value={searchPayload.signed_off}
                  handleOnChange={(newValue: boolean) =>
                    setSearchPayload({
                      ...searchPayload,
                      signed_off: newValue,
                    })
                  }
                />
              </div>

              {operatorTripFields.isLoading && (
                <>
                  <div className="divider m-0 h-px"></div>
                  <LoadingSpinner />
                </>
              )}

              {operatorTripFields?.data?.length >= 1 && selectedOperator && (
                <AdvancedSearchFilters
                  operatorTripFields={operatorTripFields}
                  handleNewTripValue={handleNewTripValue}
                  searchPayload={searchPayload}
                />
              )}
            </div>

            <div className="divider m-0 h-px"></div>

            <div className="card-footer px-4 py-2">
              <div className="flex gap-3 items-center">
                <button
                  className="btn btn-ghost text-error flex-grow sm:flex-grow-0"
                  disabled={!selectedOperator && !searchResults}
                  onClick={() => handleResetSearch()}
                >
                  <FontAwesomeIcon icon={faRedo} />
                  Reset
                </button>
                <button
                  className="btn btn-primary flex-grow sm:flex-grow-0"
                  disabled={!selectedOperator || loadingSearchResults}
                  onClick={() => {
                    setSearchPayload({ ...searchPayload, page: "1" });
                    handleSearch(selectedOperator.value?.short_name, {
                      ...searchPayload,
                      page: "1",
                    });
                  }}
                >
                  Search
                  {loadingSearchResults && <LoadingSpinner />}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full mt-4">
          <div className="card card-compact bg-base-100 border border-base-300 prose">
            <TripSearchTable
              tableRef={tableRef}
              data={searchResults.results}
              selectedOperator={selectedOperator?.value?.short_name}
              sort={searchPayload.sort}
              sort_by={searchPayload.sort_by}
              handleChangeSort={handleChangeSort}
            />
            {searchResults?.results?.length > 0 && (
              <Pagination
                searchResults={searchResults}
                handleChangePage={handleChangePage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
