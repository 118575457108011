import ModalWrapper from "../components/ModalWrapper";
import toast from "react-hot-toast";
import { useState } from "react";
import LoadingSpinner from "../icons/LoadingSpinner";

interface UpdateAppModalProps {
    updateAppModalOpen: boolean;
    setUpdateAppModalOpen: (open: boolean) => void;
}

export default function UpdateAppModal({ updateAppModalOpen, setUpdateAppModalOpen }: UpdateAppModalProps) {
    const [updating, setUpdating] = useState(false);

    const handleUpdate = () => {
        setUpdating(true);
        if (navigator.serviceWorker) {
            navigator.serviceWorker.getRegistration().then((registration) => {
                if (registration) {
                    registration.update().then(() => {
                        toast.success("App updated successfully!");
                        console.log("App updated successfully!");
                        setUpdateAppModalOpen(false);
                        setUpdating(false);
                        window.location.reload();
                    });
                } else {
                    toast.error("Error updating app. Refesh and try again.");
                    console.log("Error updating app. Refesh and try again.");
                    setUpdating(false);
                }
            });
        } else {
            toast.error("Service worker not found.");
            console.log("Service worker not found.");
            setUpdating(false);
        }
    }

    return (
        <ModalWrapper modalIsOpen={updateAppModalOpen}>
            <h3 className="mt-0">Update Available</h3>
            <div className="modal-body">
                <p>An update is available for the TRMS app.</p>
            </div>
            <div className="modal-action justify-start">
                <button className="btn btn-primary btn-outline" onClick={() => setUpdateAppModalOpen(false)}>Close</button>
                <button className="btn btn-primary" onClick={() => handleUpdate()}>Update
                    {updating && <LoadingSpinner />}
                </button>
            </div>
        </ModalWrapper>
    )
}